
export default {
  name: 'Login',
  data() {
    return {
      errorMessage: '',
      proccess: false,
      valid: true,
      form : {
        email : '',
        password : '',
      },
    }
  },
  methods: {
    login()
    {
      if(this.form.email && this.form.password)
      {
        this.proccess = true;

        this.$store.dispatch("login", this.form).then(response =>
        {
          this.valid = true;
          setTimeout(()=>{
            this.$router.push('/')
          },500)
        }).catch(error =>
        {
          var errorCode = error.code;
          this.errorMessage = error.response.data.error;
          this.valid = false;

          this.proccess = false;
        })
      }
    },
  },
  mounted()
  {
    document.title = 'Login | Kujangmas Admin'
  }

}